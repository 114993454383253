import 'regenerator-runtime/runtime';
import config from './constants/envConfigRetail';

if (process.env.BUILD_MODE === 'development') {
    Promise.resolve().then(() => {
        window.deployEnv = 'test';
        window.GLOBAL_CONFIG = config.test;
        import('./main.js');
    });
} else {
    fetch('/env.json')
        .then((res) => res.text())
        .then((res) => {
            console.log('res',res);
            const env = res.slice(8, -2);
            window.deployEnv = env || 'production';
            window.GLOBAL_CONFIG = config[env || 'production'];
            import('./main.js');
        });
}

